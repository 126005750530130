import { ActivityStep, ActivityStepResult } from './activity-step.model';

export interface QuoteActivityStep extends ActivityStep {
    result: QuoteActivityStepResult;
}

export interface QuoteActivityStepResult extends ActivityStepResult {
    values: {
        quote: QuoteResult;
    }
}

export enum QuoteResult {
    Accepted = 'accepted',
    Declined = 'declined',
}
